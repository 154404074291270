<template>

<div class="avatar"></div>

</template>

<script>

export default {

}

</script>

<style scoped>

.avatar {
	width: 100%;
	height: 530px;
	pointer-events: none;
	background-size: contain;
	background-position: 50% 0%;
	background-repeat: no-repeat;
}

.is-tablet .avatar,
.is-mobile .avatar {
	display: none;
}

.is-aa .avatar {
	background-image: url(~@/assets/avatar.aa.png);
}

.is-afa .avatar {
	background-image: url(~@/assets/avatar.afa.png);
}

.is-stars .avatar {
	background-image: url(~@/assets/avatar.stars.png);
}

</style>
